<template>
  <div class="font-selector">
    <legend
      v-if="collapsable"
      v-b-toggle="`font-selector-${_uid}`"
      tabindex="-1"
      :class="`bv-no-focus-ring col-form-label pt-0 col-form-label-${size}`"
      :style="{
        cursor: 'pointer',
      }"
    >
      {{ `${label || $t('Font')}` }}
      <span
        :style="{
          fontFamily: value.family,
          fontStyle: value.style,
          fontWeight: value.weight,
          color: value.color,
        }"
      > : {{ fontPreviewString }}
      </span>
      <feather-icon
        icon="ChevronDownIcon"
        :size="size === 'sm' ? '18' : '20'"
        style="float:right"
      />
    </legend>
    <b-collapse
      :id="`font-selector-${_uid}`"
      :visible="!collapsable"
    >
      <div class="collapse-content">
        <b-form-group
          :label="label || `${$t('Font')}`"
          :label-size="size"
          :label-sr-only="collapsable"
          class="mb-0 w-100"
        >
          <b-link
            class="col-form-label-sm pt-0"
            @click.prevent="showFilter = !showFilter"
          >
            {{ filterDisplayString }}
          </b-link>
          <b-collapse v-model="showFilter">
            <b-form-group
              :label-size="size"
            >
              <v-select
                v-model="filterOptions.categories"
                :options="googleFonts.categories"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                clearable
                :close-on-select="false"
                multiple
                :placeholder="$t('fontSelectPlaceholderByCategories')"
                :class="`mt-50 mb-1 select-size-${size}`"
              />
              <v-select
                v-model="filterOptions.subsets"
                :options="googleFonts.subsets"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                clearable
                :close-on-select="false"
                multiple
                :placeholder="$t('fontSelectPlaceholderBySubsets')"
                :class="`mt-50 mb-1 select-size-${size}`"
              />
            </b-form-group>
          </b-collapse>
          <b-form-row>
            <b-col>
              <v-select
                :value="value.family"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :options="googleFontsFiltered"
                label="family"
                :class="`select-size-${size}`"
                @input="updateFontFamily"
              >
                <template #no-options>
                  {{ $t('The list is empty') }}
                </template>
              </v-select>
            </b-col>
          </b-form-row>
          <b-form-row
            v-if="value.color || value.textAlign"
            class="mt-1"
          >
            <b-col
              v-if="value.color"
            >
              <b-form-input
                :value="value.color"
                :size="size"
                type="color"
                @input="updateFontAttr('color', $event)"
              />
            </b-col>
            <b-col
              v-if="value.textAlign"
            >
              <v-select
                :value="value.textAlign"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :clearable="false"
                :searchable="false"
                :filterable="false"
                :options="[
                  { value: 'left', label: $t('fontSelectLabelAlignLeft') },
                  { value: 'center', label: $t('fontSelectLabelAlignCenter') },
                  { value: 'right', label: $t('fontSelectLabelAlignRight') },
                ]"
                :reduce="i => i.value"
                :class="`select-size-${size}`"
                @input="updateFontAttr('textAlign', $event)"
              />
            </b-col>
          </b-form-row>
        </b-form-group>
        <b-form-row class="mt-1">
          <b-col>
            <b-form-group
              :label="$t('fontStyle')"
              :label-size="size"
              class="mb-0"
            >
              <v-select
                :value="value.style"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :disabled="fontStyles.length<2"
                :clearable="false"
                :searchable="false"
                :options="fontStyles"
                :class="`select-size-${size}`"
                @input="updateFontAttr('style', $event)"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group
              :label="$t('fontWeight')"
              :label-size="size"
              class="mb-0"
            >
              <v-select
                :value="value.weight"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :disabled="fontWeights.length<2"
                :clearable="false"
                :searchable="false"
                :options="fontWeights"
                :reduce="i => i.value"
                :class="`select-size-${size}`"
                @input="updateFontAttr('weight', $event)"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row
          v-if="value.size !== undefined"
          class="mt-1"
        >
          <b-col cols="12">
            <b-form-group
              :label="`${$t('fontSelectSize')}`"
              :label-size="size"
              class="mb-0"
            >
              <vue-slider
                v-if="fontSizeSlider"
                :value="value.size"
                :min="fontSizeRange.min"
                :max="fontSizeRange.max"
                :interval="(fontSizeRange.max - fontSizeRange.min) / fontSizeRange.steps"
                :marks="true"
                :hide-label="true"
                tooltip="none"
                class="pl-50"
                @change="updateFontAttr('size', $event)"
              />
              <b-form-input
                v-else
                :value="value.size"
                type="number"
                :min="fontSizeRange.min"
                :max="fontSizeRange.max"
                :step="(fontSizeRange.max - fontSizeRange.min) / fontSizeRange.steps"
                :size="size"
                @change="updateFontAttr('size', $event)"
              />
            </b-form-group>
          </b-col>
        </b-form-row>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import {
  BFormRow,
  BCol,
  BCollapse,
  BFormGroup,
  BFormInput,
  BLink,
  VBTooltip,
  VBToggle,
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'

import { googleFonts, loadFontFace } from '@/pdm/google-fonts'

const allFontWeightOptions = [
  { value: '100', label: 'thin' },
  { value: '200', label: 'extra light' },
  { value: '300', label: 'light' },
  { value: '400', label: 'normal' },
  { value: '500', label: 'medium' },
  { value: '600', label: 'semi bold' },
  { value: '700', label: 'bold' },
  { value: '800', label: 'extra bold' },
  { value: '900', label: 'heavy' },
]

export default {
  name: 'FontSelect',
  components: {
    BFormRow,
    BCol,
    BCollapse,
    BFormGroup,
    BFormInput,
    BLink,
    VueSlider,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
  },
  props: {
    value: {
      type: Object,
      required: true,
      default: () => {},
      validator(v) {
        const valid = v.family !== undefined && v.style !== undefined && v.weight !== undefined
        if (valid) return true
        throw new Error('Invald prop "value" in component font-select. Expected object { family, style, weight, size (optional), color (optional) }')
      },
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    size: {
      type: String,
      required: false,
      default: 'md',
      validator(v) {
        if (['sm', 'md', 'lg'].includes(v)) return true
        throw new Error(`invald prop "size" in component font-select. Expected 'sm', 'md', or 'lg', but received '${v}'`)
      },
    },
    weightOptions: {
      type: Array,
      required: false,
      default: () => [],
      validator(v) {
        const weights = allFontWeightOptions.map(i => i.label)
        if (v.every(i => weights.includes(i))) return true
        throw new Error(`invalid prop "weight-options" in component font-select.  Expected an array of ${weights}, but received '${v}'`)
      },
    },
    fontSizeSlider: { // only allow normal (400) and bold (700) weights
      type: Boolean,
      required: false,
      default: false,
    },
    collapsable: {
      type: Boolean,
      required: false,
      default: false,
    },
    fontSizeRange: {
      type: Object,
      required: false,
      default: () => ({
        min: 3, max: 99, steps: 32,
      }),
      validator(v) {
        const valid = !Number.isNaN(v.min) && !Number.isNaN(v.max) && !Number.isNaN(v.steps)
        if (valid) return true
        throw new Error('Invald prop "font-size-slider" in component font-select. Expected object { min, max, steps }')
      },
    },
  },
  data: () => ({
    filterOptions: { subsets: [], categories: [] },
    currentFont: {},
    currentFontVariants: { normal: [], italic: [] },
    showFilter: false,
    showComponent: true,
  }),

  computed: {
    googleFontsFiltered() {
      const sub = this.filterOptions.subsets
      const cat = this.filterOptions.categories
      if (!sub.length && !cat.length) {
        return this.googleFonts.faces
      }

      const ff = v => (!cat.length || cat.includes(v.category))
          && (!sub.length || v.subsets.some(i => sub.includes(i)))

      return this.googleFonts.faces.filter(ff)
    },

    fontStyles() {
      return Object.keys(this.currentFontVariants)
        .map(i => ({ value: i, label: this.$t(`fontSelectLabelStyle${i.charAt(0).toUpperCase() + i.slice(1)}`) }))
    },
    fontWeightOptions() {
      return this.weightOptions.length ? allFontWeightOptions.filter(i => this.weightOptions.includes(i.label)) : allFontWeightOptions
    },
    fontWeights() {
      const weights = this.currentFontVariants[this.value.style] || this.currentFontVariants.normal || []
      return this.fontWeightOptions
        .filter(i => weights.includes(i.value))
        .map(i => ({
          ...i,
          label: this.$t(`fontSelectLabelWeight${i.label.charAt(0).toUpperCase() + i.label.slice(1)}`),
        }))
    },
    filterDisplayString() {
      const label = this.$t('fontSelectLabelFilter')
      const c = this.filterOptions.categories.join(',')
      const s = this.filterOptions.subsets.join(',')

      if (this.showFilter) {
        return `${label} (${this.googleFontsFiltered.length}) ▾`
      }
      if (c && s) {
        return `${label}: ${c} | ${s}`
      }
      if (c || s) {
        return `${label}: ${c || s}`
      }
      return `${label} ▸`
    },
    fontPreviewString() {
      const style = this.value.style !== 'normal' ? `, ${this.value.style}` : ''
      const weight = this.value.weight !== '400' ? `, ${allFontWeightOptions.find(i => i.value === this.value.weight).label}` : ''
      return this.value.family + style + weight
    },
  },
  created() {
    this.googleFonts = googleFonts // non-reactive attribute
  },

  mounted() {
    const fontFace = this.googleFonts.faces.find(i => i.family === this.value.family)
    this.currentFont = fontFace
    this.currentFontVariants = this.getCurrentFontVariants()

    const fo = localStorage.getItem('googleFontFilterOptions')
    if (fo) this.filterOptions = JSON.parse(fo)
  },
  beforeDestroy() {
    if (this.filterOptions.subsets.length || this.filterOptions.categories.length) {
      localStorage.setItem('googleFontFilterOptions', JSON.stringify(this.filterOptions))
    } else localStorage.removeItem('googleFontFilterOptions')
  },
  methods: {
    getCurrentFontVariants() {
      const variants = { normal: [], italic: [] }
      this.currentFont.variants.forEach(v => {
        const matches = v.match(/(\d*)(italic)?$/)
        const style = matches[2] || 'normal'
        const weight = matches[1] || '400'
        variants[style].push(weight)
      })
      if (variants.normal.length === 0) delete variants.normal
      if (variants.italic.length === 0) delete variants.italic
      return variants
    },

    updateFontFamily(fontFace) {
      this.currentFont = fontFace
      this.currentFontVariants = this.getCurrentFontVariants()
      const font = { ...this.value }
      font.family = fontFace.family

      if (!this.currentFontVariants.normal) font.style = 'italic'
      if (!this.currentFontVariants.italic) font.style = 'normal'

      if (!(font.weight in this.currentFontVariants[font.style])) font.weight = '400'

      loadFontFace(font, this.currentFont).then(() => this.$emit('input', font))
    },
    updateFontAttr(attr, v) {
      const font = { ...this.value }
      font[attr] = v
      if (attr === 'style' || attr === 'weight') {
        loadFontFace(font, this.currentFont).then(() => this.$emit('input', font))
      } else this.$emit('input', font)
    },
  },
}
</script>

<style lang="sass">
  @import '@core/scss/vue/libs/vue-select.scss'

  .font-selector
    .collapsing
      width: 100%
    legend
      svg
        float: right
        transition: transform 0.35s
    legend.collapsed
      svg
        transform: rotate(-180deg)
    .vs--unsearchable
      div.vs__selected-options
        flex-wrap: nowrap
        input.vs__search
          padding: 0
          width: 0
</style>
